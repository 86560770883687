<template>
  <div class="row" v-if="items">
    <div class="col-xxl-3" v-for="(item, key) in items" v-bind:key="key">
      <kt-widget24
        :title="item.title"
        :capture="item.caption"
        :target="item.target_value"
        :progress="item.progress"
        :progress_value="item.progress_value"
        :updated_at = item.execute_date
        :background="handleClass(item.progress, item.start_date, item.end_date)"
      ></kt-widget24>
    </div>
  </div>
</template>

<script>
import KtWidget24 from "@/view/content/widgets/ktwidget/Widget24.vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import moment from "moment";
import Vue from "vue";

const MonitorRepository = RepositoryFactory.get("monitor");
Vue.use(Notifications);
export default {
  name: "GmvTag",
  components: { KtWidget24 },
  data: () => ({
    items: [],
  }),
  created() {
    this.getGmv();
  },
  methods: {
    getGmv() {
      this.items = [];
      this.$bus.$emit("show-loading", true);
      MonitorRepository.available()
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            this.$bus.$emit("show-loading", false);
            return;
          }

          this.items = response.data.data.data;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    handleClass(progress, startDate, endDate) {
      if (progress >= 100) {
        return "bg-success";
      }

      const start = moment(startDate);
      const end = moment(endDate);
      const today = moment();
      const totalDate = end.diff(start, "days");
      const todayDate = today.diff(start, "days");
      const warning = Math.round((todayDate / totalDate) * 100);

      // if (warning <= 50) {
      //   return "bg-primary";
      // }

      if (warning < 80) {
        if (progress < warning) {
          return "bg-warning";
        }
      }

      if (warning >= 80) {
        if (progress < warning) {
          return "bg-danger";
        }
      }

      return "bg-primary";
    }
  },
};
</script>

<style scoped></style>
