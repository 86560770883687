<template>
  <div class="card card-custom gutter-b">
    <div class="card-body pt-5 pb-5 pl-3 pr-3">
      <h4 class="m-widget24__title" v-if="title && title.length < 30">
        {{ title }}
      </h4>
      <h4 class="m-widget24__title" v-else-if="title && title.length >= 30" :title="title">
        {{ title.substring(0,27) + '...' }}
      </h4>
      <h4 class="m-widget24__title" v-else>
        {{ title }}
      </h4>
      <br>
      <span class="m-widget24__desc" v-if="capture && capture.length < 30">
          {{ capture }}
      </span>

      <span class="m-widget24__desc" v-else-if="capture && capture.length >= 30" :title="capture">
          {{ capture.substring(0,27) + '...' }}
      </span>

      <span class="m-widget24__desc" v-else>
          {{ capture }}
      </span>

      <span class="m-widget24__stats m--font-brand">
          {{ this.convertAmount(target) }}
      </span>
      <div class="m--space-10"></div>

      <div class="progress progress-xs">
        <div class="progress-bar" :class="background" role="progressbar" :style="`width: ${progress}%;`" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
      </div>

      <span class="m-widget24__change mt-1">
        Giá trị đạt được ({{ convertAmount(progress_value) }})
      </span>

      <span class="m-widget24__number mt-1">
      {{progress}}%
      </span>
      <div class="text-muted">
        Cập nhật lúc: {{ updated_at ? updated_at : "N/A" }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget-24",
  props: ["title", "target", "progress", "progress_value", "capture", "updated_at", "background"],
  data() {
    return {
    };
  },
  components: {},
  methods: {
    numberFormat(value) {
      var number = parseInt(value);
      if (isNaN(number) || number == null) {
        return 0;
      }
      var formatter = new Intl.NumberFormat("it-IT");
      return formatter.format(number);
    },
    convertAmount(amount) {
      const abbreviations = ["", "K", "M", "B"]; // Các đơn vị tiền tương ứng

      let index = 0;
      let converted = parseInt(amount);

      // Lặp qua các đơn vị tiền và chia số tiền cho 1000 cho đến khi nào số tiền nhỏ hơn 1000
      while (converted >= 1000) {
        converted /= 1000;
        index++;
      }

      // Làm tròn số tiền chuyển đổi và thêm đơn vị tiền tương ứng
      return `${converted.toFixed(2)}${abbreviations[index]}`;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>

<style>
  .m-widget24__title {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 600;
    color: #575962;
    max-width: 72%;
  }

  .m-widget24__desc {
    font-size: 1rem;
    font-weight: 300;
    color: #9699a2;
    display: inline-block;
    max-width: 72%;
  }

  .progress {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }

  .m-widget24__stats{
    float: right;
    margin-top: -1.43rem;
    font-size: 1.75rem;
    font-weight: 600;
  }

  .m--font-brand {
    color: #716aca !important;
  }

  .m--space-10 {
    display: block;
    height: 0;
    margin-bottom: 10px;
  }

  .m-widget24__change {
    display: inline-block;
    font-size: 1rem;
    font-weight: 300;
  }

  .m-widget24__number {
    float: right;
    display: inline-block;
    font-size: 1rem;
    font-weight: 300;
  }
</style>
